import config from '../config';

export class ApiRoutesConstants {

  private static readonly BASE_URL: string = `${config.api.url}:${config.api.port}/${config.api.version}`;

  private constructor() {
  }

  public static loginUrl(): string {
    return `${ApiRoutesConstants.BASE_URL}/auth/login`;
  }

  public static refreshTokenUrl(): string {
    return `${ApiRoutesConstants.BASE_URL}/auth/refresh`;
  }

  public static validateSessionUrl(): string {
    return `${ApiRoutesConstants.BASE_URL}/auth/validate`;
  }

  public static radioStationsUrl(id?: string): string {
    return `${ApiRoutesConstants.BASE_URL}/stations${id ? `/${id}` : ''}`;
  }

}
