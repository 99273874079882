import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  ThemeProvider,
  Typography
} from '@mui/material';
import {ReactNode, useEffect} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import styles from './styles';
import theme from '../../styles/theme';

import {AppUtils} from '../../utils/app.utils.ts';
import {AppErrorBoundaryProps} from './interfaces/app-error-boundary.props.ts';

const AppErrorBoundary = ({error}: AppErrorBoundaryProps): ReactNode => {

  const reload = () => {
    // @ts-ignore
    window.location.reload(true);
  };

  useEffect(() => {
    AppUtils.hideInitialLoader();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Container sx={styles.mainContainer}>
        <Box>
          <SentimentVeryDissatisfiedIcon sx={styles.mainIcon}/>
        </Box>
        <Typography variant='h4' gutterBottom sx={styles.title}>
          Oops! Something went wrong
        </Typography>
        <Typography variant='body1' gutterBottom sx={styles.body}>
          There was a general error in the application that does not allow it to function correctly. Please check your internet connection, reload the page and try again. If the problem persists, you can report it to <a href='support@cm-control.app' target='_blank'>support@cm-control.app</a>
        </Typography>
        <Box sx={styles.detailsBox}>
          <Accordion sx={styles.accordion} elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <Typography>Technical Details</Typography>
            </AccordionSummary>
            <AccordionDetails sx={styles.accordionDetails}>
              <Divider sx={styles.accordionDivider}/>
              <Typography variant='body2' color='text.secondary' align='left' sx={styles.pCode}>
                <code>
                  <b>Message:</b> {error?.message ?? 'No technical message available'}<br/>
                  <b>Stack trace:</b> {error?.stack ?? 'No technical stacktrace available'}
                </code>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Button variant='contained' color='primary' sx={styles.btn} onClick={() => reload()}>
          Reload Page
        </Button>
      </Container>
    </ThemeProvider>
  );
};

export default AppErrorBoundary;
