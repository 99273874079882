import {filter, map, Observable, Subject} from 'rxjs';

import {MetadataModel} from '../models/metadata.model.ts';
import {BusEventsEnum} from '../models/enums/bus-events.enum.ts';
import {EventBusMessageModel} from '../models/event-bus-message.model.ts';

class EventBusService {

  private static instance: EventBusService;

  private bus: Subject<EventBusMessageModel>;

  private readonly separator: string = ':';

  private constructor() {
    this.bus = new Subject<EventBusMessageModel>();
  }

  public static getInstance(): EventBusService {
    if (!EventBusService.instance) EventBusService.instance = new EventBusService();
    return EventBusService.instance;
  }

  public cast<T>(key: string, data?: T): void {
    if (!key.trim().length) throw new Error('key parameter must be a string and must not be empty');

    const metadata: MetadataModel<T> = new MetadataModel<T>(key, data);

    this.bus.next({key, metadata});
  }

  public on<T>(key: BusEventsEnum): Observable<MetadataModel<T>> {
    return this.bus.asObservable().pipe(
      filter((event: EventBusMessageModel): boolean => this.keyMatch(event.key, key)),
      map((event: EventBusMessageModel): MetadataModel<T> => event.metadata)
    );
  }

  private keyMatch(key: string, wildcard: string): boolean {
    const w: string = '*';
    const ww: string = '**';

    const partMatch = (wl: string, k: string): boolean => wl === w || wl === k;

    const sep: string = this.separator;
    const kArr: string[] = key.split(sep);
    const wArr: string[] = wildcard.split(sep);

    const kLen: number = kArr.length;
    const wLen: number = wArr.length;
    const max: number = Math.max(kLen, wLen);

    for (let i: number = 0; i < max; i++) {
      const cK: string = kArr[i];
      const cW: string = wArr[i];

      if (cW === ww && typeof cK !== 'undefined') return true;

      if (!partMatch(cW, cK)) return false;
    }

    return true;
  }

}

export const bus = EventBusService.getInstance();
