import {ReactNode} from 'react';
import {Backdrop, Box, Button, Card, CardActions, CardContent, CircularProgress, Typography} from '@mui/material';

import './styles/index.scss';
import store from '../../store';

import {AppWrapperProps} from './interfaces/app-wrapper.props.ts';

const AppWrapper = ({children}: AppWrapperProps): ReactNode => {
  const swEnableNotification: boolean = store((state) => state.swEnableNotification);
  const swEnableGlobalLoader: boolean = store((state) => state.swEnableGlobalLoader);
  const installUpdate: () => void = store((state) => state.installUpdate);
  const postponeUpdate: () => void = store((state) => state.postponeUpdate);

  const onReload = () => {
    installUpdate();
  };

  const onPostpone = () => {
    postponeUpdate();
  };

  return (
    <>
      {swEnableNotification &&
        <Box className={'box-notification'}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div' className={'box-notification-title'}>
                AVAILABLE UPDATE
              </Typography>
              <Typography variant='body2' color='text.secondary' className={'box-notification-body'}>
                A new update is available! Save any work you're doing and then click <b>Reload</b> to install it.
              </Typography>
            </CardContent>
            <CardActions sx={{justifyContent: 'right'}}>
              <Button size='small' onClick={onPostpone}>Postpone</Button>
              <Button size='small' onClick={onReload}>Reload</Button>
            </CardActions>
          </Card>
        </Box>
      }
      {swEnableGlobalLoader &&
        <Backdrop sx={{color: '#fff', zIndex: 1301, backgroundColor: 'rgba(0, 0, 0, 0.77)'}} open={true}>
          <CircularProgress color='inherit' size={70}/>
        </Backdrop>
      }
      {children}
    </>
  );
};

export default AppWrapper;
