import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Typography
} from '@mui/material';
import {ReactNode, useEffect, useState} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {NavigateFunction} from 'react-router/dist/lib/hooks';
import {isRouteErrorResponse, useNavigate, useRouteError} from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import {LoggerUtils} from '../../utils/logger.utils.ts';

const ErrorPage = (): ReactNode => {
  const error: any = useRouteError();
  const navigate: NavigateFunction = useNavigate();

  const [mainErrorMessage, setMainErrorMessage] = useState<string>('An unexpected error has occurred. Please try again later.');
  const [routerErrorResponse, setRouterErrorResponse] = useState<boolean>(false);

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      setRouterErrorResponse(true);

      switch (error.status) {
        case 404:
          setMainErrorMessage(`HTTP 404: This page doesn't exist!`);
          break;
        case 401:
          setMainErrorMessage(`HTTP 401: You aren't authorized to see this`);
          break;
        case 503:
          setMainErrorMessage(`HTTP 503: Looks like our API is down`);
          break;
        default:
          setMainErrorMessage(`HTTP ${error.status}: ${error.statusText}`);
          break;
      }
    } else {
      setRouterErrorResponse(false);
    }

    if (error !== undefined && error !== null) LoggerUtils.error(`[ErrorPage] Error captured on router error page:`, error);
  }, [error]);

  return (
    <Container sx={{textAlign: 'center', mt: 10}}>
      <Box>
        <SentimentVeryDissatisfiedIcon sx={{fontSize: 60}}/>
      </Box>
      <Typography variant='h4' gutterBottom sx={{mt: 6}}>
        Oops! Something went wrong
      </Typography>
      <Typography variant='body1' gutterBottom sx={{mt: 6}}>
        {mainErrorMessage}
      </Typography>
      <Box sx={{width: 500, maxWidth: '95%', margin: '30px auto 0 auto'}}>
        <Accordion sx={{mt: 10}} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography>Technical Details</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{pb: 5}}>
            <Divider sx={{mb: 4, mt: -1}}/>
            {routerErrorResponse && <Typography variant='body2' color='text.secondary' align='left'>
              <code>
                <b>Status:</b> {error.status} {error.statusText}<br/>
                <b>Message:</b> {error.data}
              </code>
            </Typography>}
            {!routerErrorResponse && <Typography variant='body2' color='text.secondary' align='left'>
              <code>
                <b>Message:</b> {error?.message ?? 'No technical details available'}
              </code>
            </Typography>}
          </AccordionDetails>
        </Accordion>
      </Box>
      {(routerErrorResponse && error.status === 404) &&
        <Button variant='contained' color='primary' sx={{mt: 10}} onClick={() => navigate('/')}>
          Go to main page
        </Button>
      }
      {(routerErrorResponse && error.status !== 404) &&
        // @ts-ignore
        <Button variant='contained' color='primary' sx={{mt: 10}} onClick={() => window.location.reload(true)}>
          Reload Page
        </Button>
      }
      {!routerErrorResponse &&
        <Button variant='contained' color='primary' sx={{mt: 10}} onClick={() => window.location.href = '/'}>
          Go Home
        </Button>
      }
    </Container>
  );
};

export default ErrorPage;
