import {SxProps} from '@mui/material';
import {Theme} from '@mui/material/styles';

const styles: Record<string, SxProps<Theme>> = {
  mainContainer: {
    textAlign: 'center',
    mt: 10
  },
  mainIcon: {
    fontSize: 60
  },
  title: {
    mt: 6
  },
  body: {
    width: 600,
    maxWidth: '90%',
    margin: '30px auto 0 auto'
  },
  detailsBox: {
    width: 800,
    maxWidth: '90%',
    margin: '30px auto 0 auto'
  },
  accordion: {
    mt: 9,
    '&.Mui-expanded:first-of-type': {
      mt: 9
    }
  },
  accordionDetails: {
    pb: 5
  },
  accordionDivider: {
    mb: 4,
    mt: -1
  },
  pCode: {
    overflowX: 'auto',
    whiteSpace: 'pre',
    paddingBottom: '12px',
  },
  btn: {
    mt: 10
  }
};

export default styles;
