import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

import config from '../config';

import createAuthSlice from './auth';
import createServiceWorkerSlice from './service-worker';
import {AuthSlice} from './auth/interfaces/auth.slice.ts';
import {ServiceWorkerSlice} from './service-worker/interfaces/service-worker.slice.ts';

type AppState = AuthSlice & ServiceWorkerSlice;

const store = create<AppState>()(
  devtools(
    (...a) => ({
      ...createAuthSlice(...a),
      ...createServiceWorkerSlice(...a)
    }),
    {
      name: 'cm-control-store',
      enabled: !config.production
    }
  )
);

export default store;
