export class WebPushNotificationsService {

  private constructor() {
  }

  public static start(): void {

  }

}
