import {DateTime} from 'luxon';
import {Area} from 'react-easy-crop';

import {LoggerUtils} from './logger.utils.ts';

export class AppUtils {

  private static readonly SEMVER_REGEX: RegExp = /^\d{1,3}\.\d{1,3}\.\d{1,3}$/;

  private constructor() {
  }

  public static hideInitialLoader(): void {
    setTimeout((): void => {
      const initLoaderElement: HTMLElement | null = document.getElementById('init-preloader');
      if (initLoaderElement) initLoaderElement.remove();
    }, 0);
  }

  public static pick(object: Record<string, any>, keys: string[]): Record<string, any> {
    return ({...keys.reduce((res: {}, key: string) => ({...res, [key]: object[key]}), {})});
  }

  public static formatCity(city: string): string {
    switch (city) {
      case 'CTG':
        return 'Cartagena';
      case 'BOG':
        return 'Bogotá D.C.';
      case 'MDE':
        return 'Medellín';
      case 'PTY':
        return 'Ciudad de Panamá';
      default:
        return 'None';
    }
  }

  public static formatUnixTime(unixTime: number): string {
    return DateTime.fromSeconds(unixTime)
      .toLocal()
      .toFormat('LLL d, t')
      .replace('a.m.', 'AM')
      .replace('p.m.', 'PM');
  }

  public static async getCroppedImg(imageSrc: string | undefined, croppedAreaPixels: Area | undefined): Promise<string> {
    if (!imageSrc || !croppedAreaPixels) return '';

    const image: HTMLImageElement = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

        if (ctx == null) return '';

        canvas.width = 300;
        canvas.height = 300;

        ctx.drawImage(image, croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height, 0, 0, 300, 300);

        const base64Image: string = canvas.toDataURL('image/png');
        resolve(base64Image.replace('data:image/png;base64,', ''));
      };

      image.onerror = (_event: Event | string, _source?: string, _lineno?: number, _colno?: number, error?: Error) => reject(error ?? new Error('Get cropped image error'));
    });
  }

  public static truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) return text;

    const startLength: number = Math.floor((maxLength - 3) / 2);
    const endLength: number = Math.ceil((maxLength - 3) / 2);

    const start: string = text.slice(0, startLength);
    const end: string = text.slice(-endLength);

    return `${start}...${end}`;
  }

  public static generateVersionCode = (versionName?: string): number => {
    if (versionName === undefined || versionName === null || String(versionName).trim() === '') return 10000;
    if (!AppUtils.SEMVER_REGEX.test(versionName)) return 10000;

    try {
      const parts: string[] = versionName.split('.');

      for (let i: number = 0; i < parts.length; i++) if (parts[i].length < 2) parts[i] = `0${parts[i]}`;

      return parseInt(parts.join('').replace(/^0+/, ''));
    } catch (e: any) {
      LoggerUtils.error('[AppUtils] Error parsing version name to version code:', e);
      return 10000;
    }
  };

  public static dtoToFormData<T extends Record<string, any>>(dto: T): FormData {
    const formData = new FormData();

    Object.keys(dto).forEach((key: string) => {
      const value: any = dto[key as keyof T];

      if (value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, String(value));
      }
    });

    return formData;
  }

  public static generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public static bytesToHuman(bytes: number | null): string {
    if (bytes == null) return '0 B';

    const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let index: number = 0;

    while (bytes >= 1024 && index < units.length - 1) {
      bytes /= 1024;
      index++;
    }

    return `${bytes.toFixed(2)} ${units[index]}`;
  }

}
