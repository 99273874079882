import {StateCreator} from 'zustand';

import {ServiceWorkerSlice} from './interfaces/service-worker.slice.ts';
import {ServiceWorkerService} from '../../services/service-worker.service.ts';

const createServiceWorkerSlice: StateCreator<ServiceWorkerSlice, [['zustand/devtools', never]], [], ServiceWorkerSlice> = (set, get) => ({
  swChecked: false,
  swEnabled: false,
  swUpdateAvailable: false,
  swEnableNotification: false,
  swEnableGlobalLoader: false,
  checkServiceWorker: async () => {
    const state: ServiceWorkerSlice = get();
    const success: boolean = await ServiceWorkerService.get().checkServiceWorker(state);
    set({swEnabled: success, swChecked: true}, false, 'SERVICE_WORKER_CHECKED');
  },
  updateAvailable: () => {
    set({swUpdateAvailable: true}, false, 'SERVICE_WORKER_UPDATE_AVAILABLE');
    set({swEnableNotification: true}, false, 'SERVICE_WORKER_ENABLE_NOTIFICATION');
  },
  hideUpdateNotification: () => {
    set({swEnableNotification: false}, false, 'SERVICE_WORKER_HIDE_NOTIFICATION');
  },
  installUpdate: () => {
    set({swEnableGlobalLoader: true}, false, 'SERVICE_WORKER_INSTALL_UPDATE');
    ServiceWorkerService.get().installUpdate();
  },
  postponeUpdate: () => {
    set({swEnableNotification: false}, false, 'SERVICE_WORKER_POSTPONE_UPDATE');
  }
});

export default createServiceWorkerSlice;
