import React, {StrictMode} from 'react';
import ReactDOM, {Root} from 'react-dom/client';
import {ErrorBoundary, Provider} from '@rollbar/react';
import {CssBaseline, ThemeProvider} from '@mui/material';

import './types';
import './styles/index.scss';

import config from './config';
import theme from './styles/theme';
import rollbarConfig from './config/rollbar.config.ts';

import AppRouter from './router/app-router';
import {LoggerUtils} from './utils/logger.utils.ts';
import {SyncService} from './services/sync.service.ts';
import AppErrorBoundary from './components/app-error-boundary';

const rootElement: HTMLElement = document.getElementById('main-content')!;
const root: Root = ReactDOM.createRoot(rootElement);

LoggerUtils.info(`[Main] == ${config.name} ==`);
LoggerUtils.info(`[Main] Version: ${config.version}, Environment: ${config.environment}, React: ${React.version}`);

SyncService.initClient();

root.render(
  <StrictMode>
    <Provider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={AppErrorBoundary}>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <AppRouter/>
        </ThemeProvider>
      </ErrorBoundary>
    </Provider>
  </StrictMode>
);
