import {
  ChannelStateChange,
  ClientOptions,
  ConnectionState,
  ConnectionStateChange,
  ErrorInfo,
  InboundMessage,
  RealtimeChannel,
  RealtimeClient
} from 'ably';
import {BaseRealtime, FetchRequest, WebSocketTransport} from 'ably/modular';

import config from '../config';

import {bus} from './event-bus.service.ts';
import {AppUtils} from '../utils/app.utils.ts';
import {LoggerUtils} from '../utils/logger.utils.ts';
import {MetadataModel} from '../models/metadata.model.ts';
import {BusEventsEnum} from '../models/enums/bus-events.enum.ts';
import {ApiRealtimeEventsConstants} from '../constants/api-realtime-events.constants.ts';

export class SyncService {

  private static fromError: boolean = false;

  private constructor() {
  }

  public static initClient(): void {
    const options: ClientOptions<any> = {
      key: config.ably.key,
      clientId: AppUtils.generateUUID(),
      logLevel: 2,
      logHandler: (msg: string): void => {
        LoggerUtils.info(`[Ably] ${String(msg)}`);
      },
      tls: true,
      transportParams: {
        heartbeatInterval: config.ably.heartbeatInterval,
        remainPresentFor: 1000
      },
      transports: ['web_socket'],
      queueMessages: false,
      disconnectedRetryTimeout: config.ably.disconnectedTimeout,
      suspendedRetryTimeout: config.ably.suspendedTimeout,
      plugins: {
        WebSocketTransport,
        FetchRequest
      }
    };

    const client: RealtimeClient = new BaseRealtime(options);
    const notificationsChannel: RealtimeChannel = client.channels.get(config.ably.channel);

    SyncService.bindListeners(client, notificationsChannel);
    SyncService.bindEvents(notificationsChannel);

    if (config.app.activeBusLogger) bus.on(BusEventsEnum.RT_STATION).subscribe((value: MetadataModel) => LoggerUtils.info('[SyncService] Event bus:', value.key));
  }

  private static bindListeners(client: RealtimeClient, notificationsChannel: RealtimeChannel): void {
    client.connection.on((stateChange: ConnectionStateChange) => {
      LoggerUtils.info(`[Ably] Changed connection status from <${stateChange.previous}> to <${stateChange.current}>`);

      const previousState: ConnectionState = stateChange.previous;
      const currentState: ConnectionState = stateChange.current;

      if (previousState === 'connecting' && currentState === 'connected' && SyncService.fromError) {
        LoggerUtils.info(`[Ably] Reconnected, sending <${BusEventsEnum.RT_API}> event...`);
        bus.cast(BusEventsEnum.RT_API);
        SyncService.fromError = false;
      }

      if (previousState === 'connected' && currentState === 'disconnected') {
        LoggerUtils.warn(`[Ably] Connection lost: ${SyncService.parseAblyError(stateChange)}`);
        SyncService.fromError = true;
      }

      if (previousState === 'disconnected' && currentState === 'connecting') {
        LoggerUtils.warn('[Ably] Trying to reconnect websocket...');
        SyncService.fromError = true;
      }

      if (previousState === 'connecting' && currentState === 'disconnected') {
        LoggerUtils.warn(`[Ably] Cannot establish connection via websocket: ${SyncService.parseAblyError(stateChange)}`);
        SyncService.fromError = true;
      }

      if (previousState === 'disconnected' && currentState === 'suspended') {
        LoggerUtils.warn(`[Ably] Trying to reconnect websocket for 2 minutes with error: ${SyncService.parseAblyError(stateChange)}`);
        SyncService.fromError = true;
      }

      if (previousState === 'suspended' && currentState === 'connecting') {
        LoggerUtils.warn('[Ably] Trying to reconnect websocket again from suspended state...');
        SyncService.fromError = true;
      }

    });

    notificationsChannel.on((stateChange: ChannelStateChange) => {
      LoggerUtils.info(`[Ably] Changed channel status from <${stateChange.previous}> to <${stateChange.current}>`);
      if (stateChange.current === 'attached') LoggerUtils.info('[Ably] Connection OK');
    });
  }

  private static bindEvents(notificationsChannel: RealtimeChannel): void {
    notificationsChannel.subscribe((m: InboundMessage) => {
      switch (m.name) {
        case ApiRealtimeEventsConstants.BACKUP:
          bus.cast(BusEventsEnum.RT_BACKUP, m.data);
          break;
        case ApiRealtimeEventsConstants.BUG:
          bus.cast(BusEventsEnum.RT_BUG, m.data);
          break;
        case ApiRealtimeEventsConstants.CAPROVER:
          bus.cast(BusEventsEnum.RT_CAPROVER, m.data);
          break;
        case ApiRealtimeEventsConstants.DEPENDENCY:
          bus.cast(BusEventsEnum.RT_DEPENDENCY, m.data);
          break;
        case ApiRealtimeEventsConstants.DEPLOYMENT:
          bus.cast(BusEventsEnum.RT_DEPLOYMENT, m.data);
          break;
        case ApiRealtimeEventsConstants.DOWNTIME:
          bus.cast(BusEventsEnum.RT_DOWNTIME, m.data);
          break;
        case ApiRealtimeEventsConstants.FORM:
          bus.cast(BusEventsEnum.RT_FORM, m.data);
          break;
        case ApiRealtimeEventsConstants.PROJECT:
          bus.cast(BusEventsEnum.RT_PROJECT, m.data);
          break;
        case ApiRealtimeEventsConstants.RESTORE:
          bus.cast(BusEventsEnum.RT_RESTORE, m.data);
          break;
        case ApiRealtimeEventsConstants.STATION:
          bus.cast(BusEventsEnum.RT_STATION, m.data);
          break;
        default:
          LoggerUtils.warn(`[SyncService] Event name not recognized or implemented: ${m.name}`);
      }
    }).then((csc: ChannelStateChange | null) => LoggerUtils.info(`[SyncService] Binded events to Ably notifications channel: ${csc?.current ?? 'null'}`))
      .catch((e: any) => LoggerUtils.error(`[SyncService] Error bind events to Ably notifications channel`, e));
  }

  private static parseAblyError(stateChange: ConnectionStateChange): string {
    if (stateChange === undefined || stateChange === null) return 'Ably didn\'t provide additional info about the connection state change';

    let reason: ErrorInfo | undefined = stateChange.reason;
    let retryIn: number | undefined = stateChange.retryIn;

    if (reason === undefined) {
      reason = {
        name: 'Undefined error',
        code: -1,
        message: 'Ably didn\'t provide details of the error',
        statusCode: -1,
        cause: 'Ably unknown error'
      };
    }

    if (retryIn === undefined) retryIn = -1;

    let message: string = reason.message;

    if (message === undefined || message === null || message.trim().length === 0) message = 'Ably didn\'t provide an error message';

    let code: number = reason.code;

    if (code === undefined || code === null) code = -1;

    let statusCode: number = reason.statusCode;

    if (statusCode === undefined || statusCode === null) statusCode = -1;

    return `${message} (Code: ${code}, Status: ${statusCode}, Retry in: ${retryIn})`;
  }

}
