import {AuthService} from './auth.service.ts';
import {SessionService} from './session.service.ts';
import {StorageService} from './storage.service.ts';
import {LoggerUtils} from '../utils/logger.utils.ts';
import {WebPushNotificationsService} from './web-push-notifications.service.ts';

export class StartupService {

  private constructor() {
  }

  public static async checkSession(): Promise<boolean> {
    LoggerUtils.info('[StartupService] Checking current session...');

    if (!StorageService.get().getAuth()) {
      LoggerUtils.info('[StartupService] User session not found');
      SessionService.logout();
      return false;
    }

    LoggerUtils.info('[StartupService] Checking user session status...');

    try {
      const success: boolean = await AuthService.check();

      if (!success) {
        LoggerUtils.info('[StartupService] User session not valid');
        SessionService.logout();
        return false;
      }

      LoggerUtils.info('[StartupService] User session validated successfully');

      setTimeout((): void => {
        WebPushNotificationsService.start();
      }, 1000);

      return true;
    } catch (e: any) {
      LoggerUtils.error(`[StartupService] Error checking user session status:`, e);
      SessionService.logout();
    }

    return false;
  }

}
