import {RouterProvider} from 'react-router-dom';
import {ReactNode, useEffect, useRef} from 'react';

import store from '../../store';

import router from '../index.tsx';
import AppWrapper from '../../components/app-wrapper';

const AppRouter = (): ReactNode => {
  const checkSession: () => Promise<void> = store((state) => state.checkSession);
  const checkServiceWorker: () => Promise<void> = store((state) => state.checkServiceWorker);
  const sessionChecked: boolean = store((state) => state.sessionChecked);
  const validSession: boolean = store((state) => state.validSession);

  const isCheckingSession = useRef<boolean>(false);
  const isCheckingSW = useRef<boolean>(false);

  useEffect(() => {
    if (isCheckingSession.current) return;
    isCheckingSession.current = true;

    (async () => {
      await checkSession();
      isCheckingSession.current = false;
    })();
  }, [checkSession]);

  useEffect(() => {
    if (isCheckingSW.current) return;
    isCheckingSW.current = true;

    (async () => {
      await checkServiceWorker();
      isCheckingSW.current = false;
    })();
  }, []);

  if (!sessionChecked) return <></>;

  return (
    <AppWrapper>
      <RouterProvider router={router(validSession)}/>
    </AppWrapper>
  );
};

export default AppRouter;
