import {createTheme, ThemeOptions} from '@mui/material';

const theme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#0097A7',
    },
    secondary: {
      main: '#FFA000',
    },
    background: {
      default: '#121212',
      paper: '#1D1D1D',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Manrope', 'system-ui', '-apple-system', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', '"Noto Sans"', '"Liberation Sans"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', '"Noto Color Emoji"'].join(',')
  },
  spacing: 4,
  shape: {
    borderRadius: 3,
  },
  components: {
    MuiList: {
      defaultProps: {
        dense: true
      }
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true
      }
    },
    MuiTable: {
      defaultProps: {
        size: 'medium'
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'medium'
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'medium'
      }
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'medium'
      }
    },
    MuiFab: {
      defaultProps: {
        size: 'medium'
      }
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small'
      }
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            boxShadow: '0 0 0 100px #292929 inset'
          },
        },
      }
    },
    MuiRadio: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small'
      }
    },
  },
};

export default createTheme(theme);
