export class ApiErrorMessagesConstants {

  private static readonly ERRORS: Record<string, string> = {
    '0': 'Oops! We were unable to get a response after a while. Please check your internet connection and try again',
    '1': 'Oops! Something happened. Please try again, reload the app or check your internet connection',
    '1001': 'There are problems on the server. Please try again',
    '1002': 'Field «{{field}}» is already registered',
    '1003': 'Field «{{field}}» is already registered',
    '1100': 'Request body is invalid',
    '1101': 'One or more parameters in the URL are invalid',
    '1200': 'Requested resource was not found or doesn\'t exist',
    '1300': 'Requested project was not found or doesn\'t exist',
    '1500': 'You\'re trying to access a resource that requires authentication',
    '1501': 'User and/or password are incorrect',
    '1502': 'Access code associated with the authentication was not found',
    '1503': 'Refresh code associated with the authentication was not found',
    '1504': 'Your session has expired. Please log in again',
    '1505': 'Refresh code associated with the authentication has already expired'
  };

  public static getDefaultErrorText(): string {
    return ApiErrorMessagesConstants.getErrorText(1);
  }

  public static getErrorText(code: number): string {
    const errorCode: string = String(code);
    return ApiErrorMessagesConstants.ERRORS[errorCode] || ApiErrorMessagesConstants.ERRORS['1000'];
  }

  public static getServerFields(): string[] {
    return [
      'dependencies.id'
    ];
  }

  public static getFieldNames(): string[] {
    return [
      'Name'
    ];
  }

}
