import {ReactNode} from 'react';
import {Navigate} from 'react-router-dom';

import store from '../../store';

import Root from '../../pages/root';
import {InternalPageProps} from './interfaces/internal-page.props.ts';

const InternalPage = ({redirectPath}: InternalPageProps): ReactNode => {
  const validSession: boolean = store((state) => state.validSession);

  if (!validSession) return <Navigate to={redirectPath} replace/>;

  return <Root/>;
};

export default InternalPage;
