import {AuthModel} from '../models/auth.model.ts';
import {TokenModel} from '../models/token.model.ts';
import {StorageService} from './storage.service.ts';
import {LoggerUtils} from '../utils/logger.utils.ts';
import {WebPushNotificationsService} from './web-push-notifications.service.ts';

export class SessionService {

  private constructor() {
  }

  public static login(token: TokenModel): void {
    const auth: AuthModel = {at: token.token, rt: token.refresh};
    StorageService.get().setAuth(auth);

    WebPushNotificationsService.start();
    LoggerUtils.info('[SessionService] User session started');
  }

  public static logout(): void {
    StorageService.get().deleteAuth();
    LoggerUtils.info('[SessionService] User session deleted');
  }

}
