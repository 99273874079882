import {useLocation} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import type {Location} from '@remix-run/router';
import {ReactNode, useEffect, useState} from 'react';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';

import {RootTitleProps} from './interfaces/root-title.props.ts';
import {NavItem} from '../../pages/root/interfaces/nav-item.ts';

const RootTitle = ({navItems}: RootTitleProps): ReactNode => {
  const location: Location = useLocation();

  const [selectedItem, setSelectedItem] = useState<NavItem | undefined>(undefined);

  useEffect((): void => {
    const item: NavItem | undefined = navItems.find((i: NavItem): boolean => i.path === location.pathname);
    if (item) setSelectedItem(item);
  }, [location]);

  return (
    <Box sx={{ml: 2}}>
      <Typography variant='h6' noWrap component='div'>
        {selectedItem && <><selectedItem.icon  sx={{verticalAlign: -3, mr: 2}}/>{selectedItem.name}</>}
        {!selectedItem && <><DashboardIcon sx={{verticalAlign: -3, mr: 1}}/>CM Control</>}
      </Typography>
    </Box>
  );
};

export default RootTitle;
