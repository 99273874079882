import {LoadingButton} from '@mui/lab';
import {MouseEvent, ReactNode, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {AccountCircle, Lock, Visibility, VisibilityOff} from '@mui/icons-material';
import {Alert, AlertTitle, Box, Collapse, IconButton, InputAdornment, TextField} from '@mui/material';

import store from '../../../store';

import {TokenModel} from '../../../models/token.model.ts';
import {LoggerUtils} from '../../../utils/logger.utils.ts';
import {AuthService} from '../../../services/auth.service.ts';
import {LoginFormModel} from './interfaces/login-form.model.ts';
import {SessionService} from '../../../services/session.service.ts';
import {ApiResponseModel} from '../../../models/api-response.model.ts';
import {ApiErrorMessagesConstants} from '../../../constants/api-error-messages.constants.ts';

const emailRegex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const LoginForm = (): ReactNode => {
  const login: () => void = store((state) => state.login);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {formState: {errors, isDirty, isValid}, handleSubmit, control} = useForm<LoginFormModel>({mode: 'onChange'});

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event: MouseEvent): void => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<LoginFormModel> = (data: LoginFormModel): void => {
    setLoading(true);
    setError(false);
    setErrorMessage('');

    AuthService.login({...data})
      .then((r: ApiResponseModel<TokenModel>): void => {
        if (!r.success) {
          setErrorMessage(r.error.api.message);
          setError(true);
          setLoading(false);
          return;
        }

        SessionService.login(r.data);
        login();
      })
      .catch((e: Error): void => {
        LoggerUtils.error('[LoginForm] Error logging:', e);

        setErrorMessage(ApiErrorMessagesConstants.getDefaultErrorText());
        setError(true);
        setLoading(false);
      });
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
      <Collapse in={!loading && error} sx={{mb: 3}}>
        <Alert variant='filled' severity='error' onClose={(): void => setError(false)}>
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Collapse>
      <Controller
        name='username'
        control={control}
        defaultValue={''}
        rules={{
          required: {value: true, message: 'Username is required'},
          minLength: {value: 6, message: 'Username must be at least 6 characters long'},
          maxLength: {value: 255, message: 'Username cannot be longer than 255 characters'},
          pattern: {value: emailRegex, message: 'Username must be a valid email address'}
        }}
        render={({field}) => (
          <TextField
            {...field}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccountCircle/>
                  </InputAdornment>
                )
              }
            }}
            id='username'
            name='username'
            label='Username'
            autoComplete='username'
            error={!!errors.username}
            helperText={errors.username ? errors.username?.message : ''}
            margin='dense'
            type='email'
            fullWidth
          />
        )}
      />
      <Controller
        name='password'
        control={control}
        defaultValue={''}
        rules={{
          required: {value: true, message: 'Password is required'},
          minLength: {value: 6, message: 'Password must be at least 6 characters long'},
          maxLength: {value: 255, message: 'Password cannot exceed 255 characters'}
        }}
        render={({field}) => (
          <TextField
            {...field}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position='start'>
                    <Lock/>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                      {showPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
            id='password'
            name='password'
            label='Password'
            autoComplete='current-password'
            error={!!errors.password}
            helperText={errors.password ? errors.password?.message : ''}
            margin='dense'
            type={showPassword ? 'text' : 'password'}
            fullWidth
            sx={{mt: 5}}
          />
        )}
      />
      <Box sx={{mt: 6, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <LoadingButton loading={loading} color='primary' variant='contained' type='submit' disabled={!isDirty || !isValid} fullWidth>
          <span>LOGIN</span>
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default LoginForm;
