export class ApiRealtimeEventsConstants {

  public static readonly BACKUP: string = 'backup';

  public static readonly BUG: string = 'bug';

  public static readonly CAPROVER: string = 'caprover';

  public static readonly DEPENDENCY: string = 'dependency';

  public static readonly DEPLOYMENT: string = 'deployment';

  public static readonly DOWNTIME: string = 'downtime';

  public static readonly FORM: string = 'form';

  public static readonly PROJECT: string = 'project';

  public static readonly RESTORE: string = 'restore';

  public static readonly STATION: string = 'station';

  private constructor() {
  }

}
