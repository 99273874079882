import DnsIcon from '@mui/icons-material/DnsOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import RadioIcon from '@mui/icons-material/RadioOutlined';
import BackupIcon from '@mui/icons-material/BackupOutlined';
import {NavItem} from '../pages/root/interfaces/nav-item.ts';
import EditNoteIcon from '@mui/icons-material/EditNoteOutlined';
import CloudOffIcon from '@mui/icons-material/CloudOffOutlined';
import BugReportIcon from '@mui/icons-material/BugReportOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSyncOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTreeOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooksOutlined';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';

export class NavigationItemsConstants {

  public static readonly ITEMS: NavItem[] = [
    {
      key: 'home',
      name: 'Home',
      path: '/dashboard',
      icon: HomeIcon
    },
    {
      key: 'notifications',
      name: 'Notifications',
      path: '/notifications',
      icon: MarkUnreadChatAltIcon
    },
    {
      key: 'projects',
      name: 'Projects',
      path: '/projects',
      icon: AccountTreeIcon
    },
    {
      key: 'backups',
      name: 'Backups',
      path: '/backups',
      icon: BackupIcon
    },
    {
      key: 'bugs',
      name: 'Bugs',
      path: '/bugs',
      icon: BugReportIcon
    },
    {
      key: 'caprover',
      name: 'CapRover',
      path: '/caprover',
      icon: DnsIcon
    },
    {
      key: 'dependencies',
      name: 'Dependencies',
      path: '/dependencies',
      icon: LibraryBooksIcon
    },
    {
      key: 'deployments',
      name: 'Deployments',
      path: '/deployments',
      icon: CloudSyncIcon
    },
    {
      key: 'downtimes',
      name: 'Downtimes',
      path: '/downtimes',
      icon: CloudOffIcon
    },
    {
      key: 'forms',
      name: 'Forms',
      path: '/forms',
      icon: EditNoteIcon
    },
    {
      key: 'radio-stations',
      name: 'Radio Stations',
      path: '/stations',
      icon: RadioIcon
    }
  ];

  private constructor() {
  }

}
