import {Outlet} from 'react-router-dom';
import {ReactNode, useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, Drawer, IconButton, Toolbar} from '@mui/material';

import RootTitle from '../../components/root-title';
import MainDrawer from '../../components/main-drawer';
import {NavigationItemsConstants} from '../../constants/navigation-items.constants.ts';

const drawerWidth: number = 240;

const RootPage = (): ReactNode => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const handleDrawerClose = (): void => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = (): void => {
    setIsClosing(false);
  };

  const handleDrawerToggle = (): void => {
    if (!isClosing) setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{display: 'flex'}}>
      <AppBar position='fixed' sx={{width: {md: `calc(100% - ${drawerWidth}px)`}, ml: {md: `${drawerWidth}px`}}} elevation={0}>
        <Toolbar>
          <IconButton color='inherit' edge='start' onClick={handleDrawerToggle} sx={{ml: 2, mr: 2, display: {md: 'none'}}}>
            <MenuIcon/>
          </IconButton>
          <RootTitle navItems={NavigationItemsConstants.ITEMS}/>
        </Toolbar>
      </AppBar>
      <Box component='nav' sx={{width: {md: drawerWidth}, flexShrink: {md: 0}}}>
        <Drawer variant='temporary' open={mobileOpen} onTransitionEnd={handleDrawerTransitionEnd} onClose={handleDrawerClose} ModalProps={{keepMounted: true}} sx={{display: {sm: 'block', md: 'none'}, '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth}}} elevation={0}>
          <MainDrawer navItems={NavigationItemsConstants.ITEMS} closeCallback={handleDrawerToggle}/>
        </Drawer>
        <Drawer variant='permanent' sx={{display: {xs: 'none', sm: 'none', md: 'block'}, '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth}}} open>
          <MainDrawer navItems={NavigationItemsConstants.ITEMS}/>
        </Drawer>
      </Box>
      <Box component='main' sx={{flexGrow: 1, p: 0, width: {md: `calc(100% - ${drawerWidth}px)`}}}>
        <Toolbar/>
        <Box sx={{p: 6}}>
          <Outlet/>
        </Box>
      </Box>
    </Box>
  );
};

export default RootPage;
