import axios, {AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from 'axios';

import config from '../config';

import {AuthService} from './auth.service.ts';
import {ApiUtils} from '../utils/api.utils.ts';
import {StorageService} from './storage.service.ts';
import {LoggerUtils} from '../utils/logger.utils.ts';
import {AuthLevelEnum} from '../models/enums/auth-level.enum.ts';
import {ApiResponseModel} from '../models/api-response.model.ts';
import {RetryQueueItemModel} from '../models/retry-queue-item.model.ts';

export class ApiService {

  private static instance: ApiService;

  private readonly axios: AxiosInstance;

  private retryQueue: RetryQueueItemModel[] = [];

  private isRefreshing: boolean = false;

  private constructor() {
    const instance: AxiosInstance = axios.create({});

    instance.defaults.timeout = config.api.timeout;
    instance.defaults.withCredentials = true;
    instance.defaults.responseType = 'json';
    instance.defaults.responseEncoding = 'utf8';
    instance.defaults.headers['X-Version'] = config.version;
    instance.defaults.headers['X-Build'] = config.build;
    instance.defaults.headers['ngsw-bypass'] = 'true';
    instance.defaults.headers.post['Content-Type'] = 'application/json';
    instance.defaults.headers.put['Content-Type'] = 'application/json';

    instance.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      if (config.authLevel == AuthLevelEnum.USER) {
        const token: string | undefined = StorageService.get().getAuth()?.at;

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          LoggerUtils.warn('[ApiService] Access token not found');
        }
      }

      LoggerUtils.info(`[ApiService] ${config.method?.toUpperCase()}: ${config.url}`);

      return config;
    });

    instance.interceptors.response.use((v: AxiosResponse) => v, async (error: AxiosError): Promise<any> => {
      LoggerUtils.error(`[ApiService] Error in API response:`, error);

      if (!error.config) return Promise.reject(error);

      if (error?.config?.skipAuthRefresh) return Promise.reject(error);

      if (!this.needToRefreshToken(error)) return Promise.reject(error);

      if (!this.isRefreshing) {
        this.isRefreshing = true;

        try {
          LoggerUtils.info(`[ApiService] Access token expired, trying to refresh...`);

          await AuthService.refreshToken();
          error.config.headers.Authorization = `Bearer ${StorageService.get().getAuth()?.at}`;

          this.retryQueue.forEach((v: RetryQueueItemModel): void => {
            this.axios.request(v.config).then((response: AxiosResponse) => v.resolve(response)).catch((err) => v.reject(err));
          });

          this.retryQueue.length = 0;

          return this.axios(error.config);
        } catch (e: any) {
          LoggerUtils.error(`[ApiService] Error refreshing access token:`, e);
          StorageService.get().deleteAuth();
          return Promise.reject(e as AxiosError);
        } finally {
          this.isRefreshing = false;
        }
      }

      LoggerUtils.info('[ApiService] Refreshing token in progress...');

      return new Promise<void>((resolve, reject): void => {
        this.retryQueue.push({config: error.config!, resolve, reject});
      });
    });

    this.axios = instance;
  }

  public static get(): ApiService {
    if (!ApiService.instance) ApiService.instance = new ApiService();
    return ApiService.instance;
  }

  public async get<T>(url: string, authLevel: AuthLevelEnum, skipAuthRefresh: boolean = false): Promise<ApiResponseModel<T>> {
    try {
      const response: AxiosResponse<T> = await this.axios.get<T, AxiosResponse<T>>(url, {authLevel, skipAuthRefresh});
      return response.data as ApiResponseModel<T>;
    } catch (e: any) {
      return ApiUtils.handleAPIError<T>(e);
    }
  }

  public async post<D, T>(url: string, body: D, authLevel: AuthLevelEnum, skipAuthRefresh: boolean = false): Promise<ApiResponseModel<T>> {
    try {
      const response: AxiosResponse<T> = await this.axios.post<T, AxiosResponse<T>, D>(url, body, {authLevel, skipAuthRefresh});
      return response.data as ApiResponseModel<T>;
    } catch (e: any) {
      return ApiUtils.handleAPIError<T>(e);
    }
  }

  public async postForm<T>(url: string, body: FormData, authLevel: AuthLevelEnum, skipAuthRefresh: boolean = false): Promise<ApiResponseModel<T>> {
    try {
      const response: AxiosResponse<T> = await this.axios.postForm<T, AxiosResponse<T>, FormData>(url, body, {authLevel, skipAuthRefresh});
      return response.data as ApiResponseModel<T>;
    } catch (e: any) {
      return ApiUtils.handleAPIError<T>(e);
    }
  }

  public async delete<T>(url: string, authLevel: AuthLevelEnum, skipAuthRefresh: boolean = false): Promise<ApiResponseModel<T>> {
    try {
      const response: AxiosResponse<T> = await this.axios.delete<T, AxiosResponse<T>>(url, {authLevel, skipAuthRefresh});
      return response.data as ApiResponseModel<T>;
    } catch (e: any) {
      return ApiUtils.handleAPIError<T>(e);
    }
  }

  private needToRefreshToken(error: AxiosError): boolean {
    if (error?.response?.status !== 401) return false;

    const data: any = error?.response?.data;

    return data?.error?.code === 1504;
  }

}
