import config from '../config';

import {ApiService} from './api.service.ts';
import {AuthModel} from '../models/auth.model.ts';
import {StorageService} from './storage.service.ts';
import {LoginDTO} from '../models/dto/login.dto.ts';
import {TokenModel} from '../models/token.model.ts';
import {LoggerUtils} from '../utils/logger.utils.ts';
import {ApiResponseModel} from '../models/api-response.model.ts';
import {AuthLevelEnum} from '../models/enums/auth-level.enum.ts';
import {ApiRoutesConstants} from '../constants/api-routes.constants.ts';

export class AuthService {

  private constructor() {
  }

  public static async login(dto: LoginDTO): Promise<ApiResponseModel<TokenModel>> {
    const service: ApiService = ApiService.get();
    return await service.post<LoginDTO, TokenModel>(ApiRoutesConstants.loginUrl(), dto, AuthLevelEnum.NONE, true);
  }

  public static async check(): Promise<boolean> {
    const service: ApiService = ApiService.get();
    const response: ApiResponseModel<string> = await service.get<string>(ApiRoutesConstants.validateSessionUrl(), AuthLevelEnum.USER);
    return response.success && response.data === 'OK';
  }

  public static async refreshToken(): Promise<void> {
    const auth: AuthModel | undefined = StorageService.get().getAuth();
    if (!auth) throw new Error('Authentication data not found');

    const body: string = JSON.stringify({accessToken: auth.at, refreshToken: auth.rt});

    const init: RequestInit = {
      method: 'POST',
      keepalive: true,
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': `${body.length}`,
        'X-Version': config.version,
        'X-Build': config.build,
        'ngsw-bypass': 'true'
      },
      body,
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      signal: AbortSignal.timeout(config.api.timeout)
    };

    const r: Response = await fetch(ApiRoutesConstants.refreshTokenUrl(), init);

    if (!r.ok || r.status !== 201) throw new Error('Refresh token failed');

    const data: Record<string, any> = await r.json();

    if (data?.success && data?.data?.token && data?.data?.refresh) {
      StorageService.get().setAuth({at: data?.data?.token, rt: data?.data?.refresh});
      LoggerUtils.info('[AuthService] Access token refreshed correctly');
    } else {
      throw new Error('Refresh token failed');
    }
  }

}
