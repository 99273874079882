import {ConfigModel} from '../models/config.model.ts';
import {EnvNameEnum} from '../models/enums/env-name.enum.ts';

const config: ConfigModel = {
  name: 'CM Control (Development)',
  environment: EnvNameEnum.DEVELOPMENT,
  production: false,
  version: __APP_VERSION__,
  build: __APP_BUILD__,
  vapidPublicKey: 'BOQWE73w6NR-QPAKto_VQfccKs7I5gf-vDnjZN_73_B3DSN-RT_rjAsqL_yyFa-UMcVp8ODecu74-_8ZE5JbgVE',
  ably: {
    key: 'a_Arsg.vstP3A:RdbISeSI9ktGWbsBU6CwrO7daSztgUeJdtHK550LtKM',
    channel: 'local-notifications',
    disconnectedTimeout: 5000,
    suspendedTimeout: 10000,
    heartbeatInterval: 10000
  },
  api: {
    url: 'http://127.0.0.1',
    port: 8080,
    version: 'v1',
    timeout: 30 * 1000
  },
  app: {
    activeProdMode: false,
    activeRollbar: false,
    activeSW: false,
    activeBusLogger: true
  },
  rollbar: {
    accessToken: '',
    codeVersion: __APP_HASH__
  }
};

export default config;
