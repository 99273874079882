export enum SwEventsEnum {
  NO_NEW_VERSION_DETECTED =  'NO_NEW_VERSION_DETECTED',
  NOTIFICATION_CLICK =  'NOTIFICATION_CLICK',
  OPERATION_COMPLETED = 'OPERATION_COMPLETED',
  PUSH = 'PUSH',
  UNRECOVERABLE_STATE = 'UNRECOVERABLE_STATE',
  VERSION_DETECTED = 'VERSION_DETECTED',
  VERSION_INSTALLATION_FAILED = 'VERSION_INSTALLATION_FAILED',
  VERSION_READY = 'VERSION_READY'
}
