declare const window: any;

export class LoggerUtils {

  private constructor() {
  }

  public static info(...args: any): void {
    console.log(...args);
  }

  public static warn(...args: any): void {
    console.warn(...args);
  }

  public static error(...args: any): void {
    console.error(...args);
    window?.Rollbar?.error(...args);
  }

}
