import {ConfigModel} from '../models/config.model.ts';
import {EnvNameEnum} from '../models/enums/env-name.enum.ts';

const config: ConfigModel = {
  name: 'CM Control',
  environment: EnvNameEnum.PRODUCTION,
  production: true,
  version: __APP_VERSION__,
  build: __APP_BUILD__,
  vapidPublicKey: 'BAsv9I0i2G3sWdhP-WdPdw11sneIZPPC7kzWuhR-6quknZJKasSb6HIM7bz3MHXC9Y--CtTu3LeTY7nrENPCfk8',
  ably: {
    key: 'a_Arsg.vstP3A:RdbISeSI9ktGWbsBU6CwrO7daSztgUeJdtHK550LtKM',
    channel: 'notifications',
    disconnectedTimeout: 5000,
    suspendedTimeout: 10000,
    heartbeatInterval: 10000
  },
  api: {
    url: 'https://api.cm-control.app',
    port: 443,
    version: 'v1',
    timeout: 30 * 1000
  },
  app: {
    activeProdMode: true,
    activeRollbar: true,
    activeSW: true,
    activeBusLogger: false
  },
  rollbar: {
    accessToken: '6e9781746ca845f49f6487a732bd6b20',
    codeVersion: __APP_HASH__
  }
};

export default config;
