import {AppUtils} from './app.utils.ts';
import {LoggerUtils} from './logger.utils.ts';
import {StorageKeysEnum} from '../models/enums/storage-keys.enum.ts';

export class StorageUtils {

  private static readonly KEY: string = '~';

  private static readonly KEYS_TO_SAVE: string[] = [StorageKeysEnum.AUTH, StorageKeysEnum.INSTALLATION, StorageKeysEnum.NOTIFICATION_TIME, StorageKeysEnum.VERSION].map((x: StorageKeysEnum): string => x.toString());

  private static readonly STORE: Record<string, any> = {};

  private constructor() {
  }

  public static loadStore(): void {
    const value: string | null = localStorage.getItem(StorageUtils.KEY);
    const data: Record<string, any> = {};

    if (value && value.length > 2) {
      try {
        const parsedData: Record<string, any> = JSON.parse(atob(value.substring(3)));
        Object.assign(data, parsedData);
      } catch (e) {
        LoggerUtils.error('[StorageUtils] Error parsing store:', e);
      }
    }

    Object.assign(StorageUtils.STORE, data);

    LoggerUtils.info('[StorageUtils] Storage loaded');
  }

  public static get<T>(key: StorageKeysEnum): T | undefined {
    return StorageUtils.read<T>(key);
  }

  public static set<T>(key: StorageKeysEnum, data: T): void {
    StorageUtils.update<T>(key, data);
  }

  public static delete(key: StorageKeysEnum): void {
    StorageUtils.remove(key);
  }

  private static read<T>(key: StorageKeysEnum): T | undefined {
    return StorageUtils.STORE[key];
  }

  private static update<T>(key: StorageKeysEnum, data: T): void {
    Object.assign(StorageUtils.STORE, {...StorageUtils.STORE, [key]: {...data}});
    StorageUtils.putStorage();
  }

  private static remove(key: StorageKeysEnum): void {
    Object.assign(StorageUtils.STORE, {...StorageUtils.STORE, [key]: undefined});
    StorageUtils.putStorage();
  }

  private static putStorage(): void {
    setTimeout((): void => {
      const data: Record<string, any> = AppUtils.pick(StorageUtils.STORE, StorageUtils.KEYS_TO_SAVE);
      const base64: string = btoa(JSON.stringify(data));
      localStorage.setItem(StorageUtils.KEY, `aJk${base64}`);
    }, 10);
  }

}
