export enum BusEventsEnum {
  APP_ALL = '**',

  RT_API = 'RT:API',
  RT_BACKUP = 'RT:BACKUP',
  RT_BUG = 'RT:BUG',
  RT_CAPROVER = 'RT:CAPROVER',
  RT_DEPENDENCY = 'RT:DEPENDENCY',
  RT_DEPLOYMENT = 'RT:DEPLOYMENT',
  RT_DOWNTIME = 'RT:DOWNTIME',
  RT_FORM = 'RT:FORM',
  RT_PROJECT = 'RT:PROJECT',
  RT_RESTORE = 'RT:RESTORE',
  RT_STATION = 'RT:STATION'
}
