import {StateCreator} from 'zustand';

import {AppUtils} from '../../utils/app.utils.ts';
import {AuthSlice} from './interfaces/auth.slice.ts';
import {SessionService} from '../../services/session.service.ts';
import {StartupService} from '../../services/startup.service.ts';

const createAuthSlice: StateCreator<AuthSlice, [['zustand/devtools', never]], [], AuthSlice> = (set) => ({
    validSession: false,
    sessionChecked: false,
    login: () => set({validSession: true}, false, 'AUTH_LOGIN'),
    logout: () => {
      SessionService.logout();
      set({validSession: false}, false, 'AUTH_LOGOUT');
    },
    checkSession: async () => {
        const success = await StartupService.checkSession();
        set({validSession: success, sessionChecked: true}, false, 'AUTH_CHECKED_SESSION');
        setTimeout(() => AppUtils.hideInitialLoader(), 100);
    }
});

export default createAuthSlice;
