import {ReactNode} from 'react';

import PageTitle from '../../components/page-title';
import {PageTitleProps} from '../../components/page-title/interfaces/page-title.props.ts';

const DowntimesPage = ({title}: PageTitleProps): ReactNode => {
  return (
    <>
      <PageTitle title={title}/>
      <h1>Downtimes</h1>
    </>
  );
};

export default DowntimesPage;
