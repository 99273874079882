import {createBrowserRouter, Navigate} from 'react-router-dom';

import BugsPage from '../pages/bugs';
import ErrorPage from '../pages/error';
import FormsPage from '../pages/forms';
import LoginPage from '../pages/login';
import BackupsPage from '../pages/backups';
import ProjectsPage from '../pages/projects';
import CaproverPage from '../pages/caprover';
import DashboardPage from '../pages/dashboard';
import DowntimesPage from '../pages/downtimes';
import DeploymentsPage from '../pages/deployments';
import DependenciesPage from '../pages/dependencies';
import InternalPage from '../components/internal-page';


const router = (userIsLogged: boolean): ReturnType<typeof createBrowserRouter> => {
  return createBrowserRouter([
    {
      path: '/',
      element: userIsLogged ? <Navigate to='/dashboard' replace/> : <Navigate to='/login' replace/>,
      errorElement: <ErrorPage/>
    },
    {
      path: '/login',
      element: userIsLogged ? <Navigate to='/dashboard' replace/> : <LoginPage/>,
      errorElement: <ErrorPage/>
    },
    {
      element: <InternalPage redirectPath={'/login'}/>,
      errorElement: <ErrorPage/>,
      children: [
        {
          path: '/dashboard',
          element: <DashboardPage title={'Dashboard'}/>
        },
        {
          path: '/projects',
          element: <ProjectsPage title={'Projects'}/>
        },
        {
          path: '/backups',
          element: <BackupsPage title={'Backups'}/>
        },
        {
          path: '/bugs',
          element: <BugsPage title={'Bugs'}/>
        },
        {
          path: '/caprover',
          element: <CaproverPage title={'CapRover'}/>
        },
        {
          path: '/dependencies',
          element: <DependenciesPage title={'Dependencies'}/>
        },
        {
          path: '/deployments',
          element: <DeploymentsPage title={'Deployments'}/>
        },
        {
          path: '/downtimes',
          element: <DowntimesPage title={'Downtimes'}/>
        },
        {
          path: '/forms',
          element: <FormsPage title={'Forms'}/>
        },
        {
          path: '/stations',
          async lazy() {
            let {RadioStationsPage} = await import('../pages/radio-stations/index.tsx')
            return {Component: RadioStationsPage}
          },
        }
      ]
    }
  ]);
};

export default router;
