import {Configuration, Dictionary} from 'rollbar';

import config from './index.ts';

const rollbarConfig: Configuration = {
  accessToken: config.rollbar.accessToken,
  addErrorContext: true,
  autoInstrument: true,
  captureDeviceInfo: true,
  captureEmail: true,
  captureIp: true,
  captureLambdaTimeouts: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureUsername: true,
  enabled: config.app.activeRollbar,
  environment: 'production',
  payload: {
    context: '',
    environment: 'production',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: config.rollbar.codeVersion,
        guess_uncaught_frames: true
      }
    },
    server: {
      branch: 'main',
      host: window.location.host
    }
  },
  sendConfig: false,
  transform: (data: Dictionary) => {
    data['framework'] = 'react';
    data['custom'] = {
      version: config.version
    };
    data['context'] = window.location.pathname;
    data['code_version'] = config.rollbar.codeVersion;
    data['timestamp'] = Math.floor(new Date().getTime() / 1000);
  },
  verbose: false
};

export default rollbarConfig;
