import {ReactNode, useEffect} from 'react';

import {PageTitleProps} from './interfaces/page-title.props.ts';

const PageTitle = ({title}: PageTitleProps): ReactNode => {

  useEffect((): void => {
    document.title = `${title} | CM Control`;
  }, [title]);

  return <></>;
};

export default PageTitle;
