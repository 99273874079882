import {ReactNode} from 'react';
import type {Location} from '@remix-run/router';
import {useLocation, useNavigate} from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import {NavigateFunction} from 'react-router/dist/lib/hooks';
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar} from '@mui/material';

import './styles/index.scss';
import store from '../../store';

import {NavItem} from '../../pages/root/interfaces/nav-item.ts';
import {MainDrawerProps} from './interfaces/main-drawer.props.ts';

const MainDrawer = ({navItems, closeCallback}: MainDrawerProps): ReactNode => {
  const logout: () => void = store((state) => state.logout);
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  const goTo = (path: string): void => {
    if (closeCallback) closeCallback();
    navigate(path);
  };

  return (
    <div>
      <Toolbar>
        <Box component='div'>
          <img src='/img/logo.png' alt='CM Control' className='img-drawer' width={160} height={51}/>
        </Box>
      </Toolbar>
      <Divider/>
      <List>
        {navItems.map((item: NavItem): ReactNode => (
          <ListItem key={item.key} disablePadding>
            <ListItemButton sx={{py: 2}} selected={item.path === location.pathname} onClick={() => goTo(item.path)}>
              <ListItemIcon sx={{mt: '-2px'}}>
                <item.icon/>
              </ListItemIcon>
              <ListItemText primary={item.name.toUpperCase()} sx={{ml: -3}}/>
            </ListItemButton>
          </ListItem>
        ))}
        <Divider/>
        <ListItem disablePadding>
          <ListItemButton sx={{py: 2}} onClick={() => logout()}>
            <ListItemIcon sx={{mt: '-2px'}}>
              <LogoutIcon/>
            </ListItemIcon>
            <ListItemText primary={'LOGOUT'} sx={{ml: -3}}/>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export default MainDrawer;
