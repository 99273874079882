import {AppUtils} from '../utils/app.utils.ts';

export class MetadataModel<T = any> {

  private readonly _id: string;

  private readonly _key: string;

  private readonly _data?: T;

  private readonly _timestamp: number;

  constructor(key: string, data?: T) {
    this._id = AppUtils.generateUUID();
    this._key = key;
    this._data = data;
    this._timestamp = new Date().getTime();
  }

  public get id(): string {
    return this._id;
  }

  public get key(): string {
    return this._key;
  }

  public get data(): T | undefined {
    return this._data;
  }

  public get timestamp(): number {
    return this._timestamp;
  }

}
