import config from '../config';

import {LoggerUtils} from '../utils/logger.utils.ts';
import {SwEventsEnum} from '../models/enums/sw-events.enum.ts';
import {ServiceWorkerSlice} from '../store/service-worker/interfaces/service-worker.slice.ts';

export class ServiceWorkerService {

  private static instance: ServiceWorkerService;

  private state: ServiceWorkerSlice | undefined;

  private started: boolean = false;

  private installationID: number = -1;

  private checkUpdateID: number = -1;

  private constructor() {
  }

  public static get(): ServiceWorkerService {
    if (!ServiceWorkerService.instance) ServiceWorkerService.instance = new ServiceWorkerService();
    return ServiceWorkerService.instance;
  }

  public async checkServiceWorker(state: ServiceWorkerSlice): Promise<boolean> {
    LoggerUtils.info('[ServiceWorkerService] Checking SW...');

    this.state = state;

    if (!config.app.activeSW) {
      LoggerUtils.warn('[ServiceWorkerService] SW installation disabled by configuration');
      return false;
    }

    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      LoggerUtils.warn('[ServiceWorkerService] SW not supported in this browser');
      return false;
    }

    LoggerUtils.info(`[ServiceWorkerService] Support for SW ok, installing...`);

    this.registerListeners();
    return await this.registerSW();
  }

  public installUpdate(): void {
    const installationID: number = Math.round(Math.random() * 10000000);
    LoggerUtils.info(`[ServiceWorkerService] Installing new app version (Installation ID: ${installationID})...`);

    this.sendInstallMessage(installationID);
  }

  private registerListeners(): void {
    navigator.serviceWorker.addEventListener('controllerchange', () => this.controllerChange());
    navigator.serviceWorker.addEventListener('message', (evt: MessageEvent<any>) => this.processMessage(evt.data));
    navigator.serviceWorker.addEventListener('messageerror', (evt: MessageEvent<any>) => this.processMessageError(evt.data));
  }

  private async registerSW(): Promise<boolean> {
    return new Promise((resolve) => {
      navigator.serviceWorker.register('sw.js', {scope: './'})
        .then(() => {
          LoggerUtils.info('[ServiceWorkerService] SW registered');
          this.started = true;

          resolve(true);
        })
        .catch((e: any) => {
          LoggerUtils.error('[ServiceWorkerService] SW registration failed:', e);
          resolve(false);
        });
    });
  }

  private controllerChange(): void {
    if (navigator.serviceWorker.controller !== null) {
      LoggerUtils.info(`[ServiceWorkerService] SW installed, sending INITIALIZE message...`);
      navigator.serviceWorker.controller.postMessage({action: 'INITIALIZE'});
    }
  }

  private processMessage(data: any): void {
    LoggerUtils.info(`[ServiceWorkerService] Message received from SW: ${JSON.stringify(data)}`);

    if (data?.type === undefined) {
      LoggerUtils.warn(`[ServiceWorkerService] Message received from SW without complete data`);
      return;
    }

    if (data.type === SwEventsEnum.VERSION_DETECTED) {
      this.versionDetected(data);
      return;
    }

    if (data.type === SwEventsEnum.VERSION_READY) {
      this.versionReady(data);
      return;
    }

    if (data.type === SwEventsEnum.OPERATION_COMPLETED && data.nonce === this.installationID) {
      if (data.result) {
        this.versionInstalled();
      } else {
        this.versionNotInstalled(data);
      }

      return;
    }

    if (data.type === SwEventsEnum.OPERATION_COMPLETED && data.nonce === this.checkUpdateID) {
      if (!data.result) this.versionNotAvailable(data);

      this.checkUpdateID = -1;
      return;
    }

    if (data.type === SwEventsEnum.NO_NEW_VERSION_DETECTED) {
      LoggerUtils.info('[ServiceWorkerService] No new version detected');
      return;
    }

    LoggerUtils.warn(`[ServiceWorkerService] No processing actions for message type from SW '${data.type}'`, data);
  }

  private processMessageError(data: any) {
    LoggerUtils.warn(`[ServiceWorkerService] Error message received:`, data);
  }

  private versionDetected(data: any): void {
    LoggerUtils.info(`[ServiceWorkerService] New version detected, downloading. New version: ${data.version.hash}`);
  }

  private versionReady(data: any): void {
    LoggerUtils.info(`[ServiceWorkerService] New version ready to install, showing notification. Current version: ${data.currentVersion.hash}, New version: ${data.latestVersion.hash}`);
    this.state?.updateAvailable();
  }

  private sendInstallMessage(id: number): void {
    if (this.started && navigator.serviceWorker.controller !== null) {
      LoggerUtils.info(`[ServiceWorkerService] Sending ACTIVATE_UPDATE with installation ID ${id} to SW...`);
      navigator.serviceWorker.controller.postMessage({action: 'ACTIVATE_UPDATE', nonce: id});
      this.installationID = id;
    } else {
      LoggerUtils.warn(`[ServiceWorkerService] Cannot send ACTIVATE_UPDATE with installation ID ${id} to SW, it's not started or controller is null`);
    }
  }

  private versionInstalled(): void {
    LoggerUtils.info(`[ServiceWorkerService] New version was installed, reloading application browser...`);
    setTimeout(() => window.location.pathname = '/', 1000);
  }

  private versionNotInstalled(data: any): void {
    LoggerUtils.warn(`[ServiceWorkerService] New version was not installed (result: ${data.result}; error: ${data.error}), reloading application browser anyway...`);
    setTimeout(() => window.location.pathname = '/', 1000);
  }

  private versionNotAvailable(data: any): void {
    LoggerUtils.info(`[ServiceWorkerService] There's not new version available (${data.type} with result ${data.result})`);
  }

}
