import {ReactNode} from 'react';

import PageTitle from '../../components/page-title';
import {PageTitleProps} from '../../components/page-title/interfaces/page-title.props.ts';

const DashboardPage = ({title}: PageTitleProps): ReactNode => {
  return (
    <>
      <PageTitle title={title}/>
      <h1>Dashboard</h1>
    </>
  );
};

export default DashboardPage;
