import {AxiosError, AxiosResponse} from 'axios';

import {ApiStatusModel} from '../models/api-status.model.ts';
import {ApiResponseModel} from '../models/api-response.model.ts';
import {ApiErrorResponseModel} from '../models/api-error-response.model.ts';
import {ApiErrorMessagesConstants} from '../constants/api-error-messages.constants.ts';

export class ApiUtils {

  private constructor() {
  }

  public static handleAPIError<T>(error: AxiosError): ApiResponseModel<T> {
    const api: ApiStatusModel = ApiUtils.getAPIStatus(error as any);

    if (error === undefined || error === null) {
      const err: ApiErrorResponseModel = {fromApi: false, httpCode: -1, httpText: '', api};
      return {success: false, totalRecords: 0, data: null as T, error: err};
    }

    const err: ApiErrorResponseModel = {
      fromApi: (error?.response?.status ?? -1) > 0,
      httpCode: error?.response?.status ?? -1,
      httpText: error?.response?.statusText ?? '',
      api
    };

    return {success: false, totalRecords: 0, data: null as T, error: err};
  }

  private static getAPIStatus(error?: AxiosError<Record<string, any>, any>): ApiStatusModel {
    if (error?.code === AxiosError.ETIMEDOUT || error?.code === AxiosError.ECONNABORTED || error?.code === AxiosError.ERR_CANCELED) {
      const code: number = 0;
      const message: string = ApiErrorMessagesConstants.getErrorText(code);

      return {code, message};
    }

    if (error?.code === AxiosError.ERR_NETWORK) {
      const code: number = 1;
      const message: string = ApiErrorMessagesConstants.getErrorText(code);

      return {code, message};
    }

    const response: AxiosResponse<Record<string, any>, any> | undefined = error?.response;

    const code: number = Number(response?.data?.error?.code || 1);
    const message: string = ApiErrorMessagesConstants.getErrorText(code);

    if (code === 1002 || code === 1003) {
      return {code, message: ApiUtils.getMessageForConflict(code, response?.data?.error?.message ?? '')};
    } else {
      return {code, message};
    }
  }

  private static getMessageForConflict(code: number, message: string): string {
    let position: number = 0;
    let fieldName: string;

    if (ApiErrorMessagesConstants.getServerFields().some((element: string, index: number): boolean => {
      if (message.indexOf(element) >= 0) {
        position = index;
        return true;
      } else {
        return false;
      }
    })) {
      fieldName = ApiErrorMessagesConstants.getFieldNames()[position];
    } else {
      return 'There was an error executing the operation. Please try again';
    }

    return ApiErrorMessagesConstants.getErrorText(code).replace('{{field}}', fieldName);
  }

}
