import {ReactNode} from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Avatar, Box, Card, CardContent, Container, Typography} from '@mui/material';

import LoginForm from '../../components/forms/login-form';

const LoginPage = (): ReactNode => {
  return (
    <Container component='main' maxWidth='xs'>
      <Box sx={{marginTop: 16, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Avatar sx={{m: 1, bgcolor: 'primary.main'}}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component='h1' variant='h5'>
          Login
        </Typography>
        <Card sx={{marginTop: 12}}>
          <CardContent>
            <LoginForm/>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default LoginPage;
