import {AuthModel} from '../models/auth.model.ts';
import {StorageUtils} from '../utils/storage.utils.ts';
import {StorageKeysEnum} from '../models/enums/storage-keys.enum.ts';

export class StorageService {

  private static instance: StorageService;

  private constructor() {
    StorageUtils.loadStore();
  }

  public static get(): StorageService {
    if (!StorageService.instance) StorageService.instance = new StorageService();
    return StorageService.instance;
  }

  public getAuth(): AuthModel | undefined {
    return StorageUtils.get<AuthModel>(StorageKeysEnum.AUTH);
  }

  public setAuth(auth: AuthModel): void {
    StorageUtils.set<AuthModel>(StorageKeysEnum.AUTH, auth);
  }

  public deleteAuth(): void {
    StorageUtils.delete(StorageKeysEnum.AUTH);
  }

}
